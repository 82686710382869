@import 'variaveis';

body{
    background: url('../images/_template.jpg');
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}


h1{
    font-weight: 100;
    color: $cor_input;
    text-align: center;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(79, 98, 148)

}

.form{
    background: rgba(255, 255, 255, 0.2);
    padding: 20px;
    border-radius: 8px;
    height: 300px;
    
    
}

input{
    display: block;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    border: 1px solid $cor_input;
    padding: 10px 15px;
    margin-bottom: 10px;
    font-size: 14px;
}

/*input.submit,
input[type="submit"] {
  background: #ec5990;
  color: white;
  text-transform: uppercase;
  border: none;
  margin-top: 40px;
  padding: 20px;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 10px;
}

input.submit:hover,
input[type="submit"]:hover {
  background: #bf1650;
}

input.submit:active,
input[type="button"]:active,
input[type="submit"]:active {
  transition: 0.3s all;
  transform: translateY(3px);
  border: 1px solid transparent;
  opacity: 0.8;
}

input:disabled {
  opacity: 0.4;
}

input[type="button"]:hover {
  transition: 0.3s all;
}

input[type="button"],
input[type="submit"] {
  -webkit-appearance: none;
}
*/

header{
  
    padding: 10px;
    padding-bottom: 40px;
    padding-top: 10vh;
    text-align: center;
    align-items: center;
}

button{
    background-color: $cor_uceff !important;
    border: 1px solid $cor_uceff !important;
    font-size: 18px;
}

.span-form{
    margin-right: 6px;
    font-size: 29px;
    
}

.button-effect:hover{
    background-color: rgba(241, 120, 6, 0.2);
    border: 1px solid rgb(87, 85, 85) !important;
}

.botao-buscar{
    text-align: center;
    padding-top: 15px;
}

.row-style{
    justify-content: center;
}

.lista{
    
    color:black;
    padding-top:10px;
    background-color: rgba(255, 255, 255, 0.6);
}

.tam-caixa{
    width: 100% !important;
}